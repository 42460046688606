export const snafuNftAddress = "0xED1eFC6EFCEAAB9F6d609feC89c9E675Bf1efB0a";
export const snafu20Address = "0x27B9C2Bd4BaEa18ABdF49169054c1C1c12af9862";
export const snafu721Address = "0x683B799b7ee741f00CCea34AcaDC32ba23E8982a";

export const xdaiRPC = "https://rpc.gnosischain.com";

export const recaptchaSiteKey = "6Lfh9YgbAAAAACR-yXOXAqcGez4kETezd5iOU1ic";

export const commonFarmAddress = "0x8dDc7167e9F838f2e32FaBA229A53d4a48D0aa8d";
export const rareFarmAddress = "0x88CfEea7BE8A7695A3012276e8C68bf303Afe49a";
export const ultraRareFarmAddress = "0x662D6C48818abD8bBCb7cb589D24E2Fe11Cdba52";

export const xDaiSnafuLiquidityAddress = "0xd6c8ad00302ca94952e7746d956e8b45b0ea90e3"
export const pSnafuAddress = "0x0f2a2AD6665Bc94EA2E665524daF08Dd7C21CB1c"

export const leaderboardAddressToFilter = [
    "0x27B9C2Bd4BaEa18ABdF49169054c1C1c12af9862", //SNAFU POOL
    "0xEA912373bEf07E06F04fdE1d8218eb6C77cFF67A", //DAO
    "0x652d53227d7013f3FbBeA542443Dc2eeF05719De", //OWNER SNAFU NFT
    "0x8dDc7167e9F838f2e32FaBA229A53d4a48D0aa8d", //$SNAFU FARM
    "0x88CfEea7BE8A7695A3012276e8C68bf303Afe49a", //LP FARM
    "0x670BF58c124A958Ec5715b21A57bb2F174Cc14cA", //CAVEAU SNAFU
    "0x191b8c73b4eFA062F548AB9C762c6FC8512493A8", //ALESSANDRO NALLI
    "0xEBd63929331A97F0fC62409Ce1Ee29A0908CB519", //ANIMA
    "0xeB1f193F347133bF27C81B23D4d2D6CD13167c66", //JASMINE
    "0x77beAf50CEc4206d3F09D41aE584222D53c74A2D", //EBBY
    "0xF3361Fd22655bff05C617EDe2E061BE8A90c47f4", //ELISA
    "0xCd6fcb4EcF97365fe159284B9Acc3C8519F5B6c4", //BUDA
    "0x86ee5eb5D97E9408223be407eA76C2323F7991E3", //METT
    "0x37a67ad8Faac22830c87011935B841f982ea50eB", //SEBA
    "0x097F16e2931a86107dD0F900c0b5f060889F65a2", //SNAFU CASSA
    "0x662D6C48818abD8bBCb7cb589D24E2Fe11Cdba52", //FARMING
]