var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex"}},[(!_vm.isConnected)?_c('div',{attrs:{"cols":"1"}},[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},on:{"click":_vm.connectWallet}},[_vm._v(" Connect wallet ")])],1):_c('div',{attrs:{"cols":"9","no-gutters":""}},[_c('v-chip',{staticStyle:{"margin-bottom":"10px","padding-left":"0"},attrs:{"color":_vm.isXdai ? '#303030' : '#bc423e',"text-color":_vm.isXdai ? '#fff' : '#fff'}},[_c('router-link',{staticClass:"accountLinkHeader",attrs:{"to":{ name: 'Wallet'}}},[_c('v-chip',{staticStyle:{"border":"#A7A7A7","cursor":"pointer","background-color":"#f3f3f3"}},[_c('v-avatar',{staticStyle:{"width":"50px !important","height":"50px !important","margin-left":"-15px","padding-right":"5px"},attrs:{"left":""}},[_c('v-img',{attrs:{"src":"/pfp/unknown.jpeg"}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"color":"#303030","font-weight":"500"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("abbreviateAddress")(_vm.account)))])]}}])},[_c('span',[_vm._v("Go to your profile")])])],1)],1),_c('v-tooltip',{staticStyle:{"text-align":"center"},attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"padding-left":"5px","font-weight":"500","cursor":"pointer"},on:{"click":_vm.addGnosisChainNetwork}},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("networkName")(_vm.chainId)))])]}}])},[_c('span',[_vm._v("Click here to switch to the correct network!")])])],1)],1),(_vm.isConnected)?_c('div',{staticStyle:{"margin-left":"10px","margin-top":"2.5px"},attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"rgb(0 0 0 / 89%)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-h6",staticStyle:{"background-color":"whitesmoke","margin-bottom":"10px"},attrs:{"color":"#303030","dark":"","icon":"","small":""},on:{"click":_vm.disconnectWallet}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25px"}},[_vm._v("mdi-exit-to-app")])],1)]}}],null,false,2604102397)},[_c('span',[_vm._v("Disconnect")])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }